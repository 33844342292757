import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from '@react-pdf/renderer'

import OpenSansRegular from '../../../../static/fonts/OpenSans-Regular.ttf'
import OpenSansBold from '../../../../static/fonts/OpenSans-Bold.ttf'

Font.register({
  family: 'Open Sans',
  fonts: [{ src: OpenSansRegular }, { src: OpenSansBold, fontWeight: 700 }],
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    fontFamily: 'Open Sans',
    fontSize: 10,
    padding: 30,
  },
  title: {
    fontSize: 36,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  heading1: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 12,
  },
  heading2: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 4,
  },
  heading3: {
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  heading4: {
    fontSize: 10,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  textSmall: {
    fontSize: 6,
  },
  textMedium: {
    fontSize: 7,
  },
  textBold: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  textLine: {
    width: '100%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    padding: '3 4',
  },
  box1: {
    display: 'table',
    width: 196,
    height: 124,
    borderStyle: 'solid',
    borderWidth: 2,
    textAlign: 'left',
  },
  table: {
    display: 'table',
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { padding: 2 },
  sig: {
    width: 48,
    height: 36,
  },
})

const BillOfLadingPdf = ({ data }) => (
  <Document style={{ width: '100%' }}>
    <Page size="LETTER" style={styles.page}>
      <View style={{ textAlign: 'center' }}>
        <Text style={styles.title}>STRAIGHT BILL OF LADING</Text>
        <Text style={styles.subtitle}>
          SHORT FORM - ORIGINAL NOT NEGOTIABLE
        </Text>
      </View>
      <View style={[styles.textSmall, { marginTop: 12 }]}>
        <Text>
          RECEIVED, subject to the classifications and lawfully filed tariffs in
          effect on the date of issue of this origianl bill of lading.
        </Text>
        <Text>
          the property described below, in apparent good order, except as noted
          (contents and condition of contents of packages unknown), marked,
          consigned and destined as indicated below,
        </Text>
      </View>

      {/* SHIPPER Information */}
      <View style={{ flexDirection: 'row', marginTop: 12 }}>
        <Text
          style={[
            styles.heading4,
            { padding: 4, paddingLeft: 0, width: 72, textAlign: 'right' },
          ]}
        >
          shipper:
        </Text>
        <View style={styles.box1}>
          <Text style={{ padding: 4 }}>{data.shipper}</Text>
        </View>
        <View
          style={[
            styles.heading4,
            { lineHeight: 2, padding: 4, width: 128, textAlign: 'right' },
          ]}
        >
          <Text>date:</Text>
          <Text>shipment #</Text>
          <Text>bill of lading no.</Text>
          <Text>p / o delivery no.</Text>
          <Text>truck no.</Text>
          <Text>trailer no.</Text>
        </View>
        <View style={[styles.box1, { width: 148 }]}>
          <Text style={styles.textLine}>{data.shippingDate}</Text>
          <Text style={styles.textLine}>{data.shipmentNo}</Text>
          <Text style={styles.textLine}>{data.billNo}</Text>
          <Text style={styles.textLine}>{data.deliveryNo}</Text>
          <Text style={styles.textLine}>{data.truckNo}</Text>
          <Text style={[styles.textLine, { borderBottomWidth: 0 }]}>
            {data.trailerNo}
          </Text>
        </View>
      </View>

      {/* CONSIGNEE Information */}
      <View style={{ flexDirection: 'row', marginTop: 12 }}>
        <Text
          style={[
            styles.heading4,
            { padding: 4, paddingLeft: 0, width: 72, textAlign: 'right' },
          ]}
        >
          consignee:
        </Text>
        <View style={styles.box1}>
          <Text style={{ padding: 4 }}>{data.consignee}</Text>
        </View>
        <View style={{ flexDirection: 'column' }}>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={[
                styles.heading4,
                { lineHeight: 2, padding: 4, width: 128, textAlign: 'right' },
              ]}
            >
              <Text>pick up - time in:</Text>
              <Text>pick up - time out:</Text>
            </View>
            <View style={[styles.box1, { width: 148, height: 42.33 }]}>
              <Text style={styles.textLine}>{data.pickup.timeIn}</Text>
              <Text style={[styles.textLine, { borderBottomWidth: 0 }]}>
                {data.pickup.timeOut}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 12 }}>
            <View
              style={[
                styles.heading4,
                {
                  lineHeight: 2,
                  padding: 4,
                  width: 128,
                  textAlign: 'right',
                },
              ]}
            >
              <Text>delivery - time in:</Text>
              <Text>delivery - time out:</Text>
            </View>
            <View style={[styles.box1, { width: 148, height: 42.33 }]}>
              <Text style={styles.textLine}>{data.delivery.timeIn}</Text>
              <Text style={[styles.textLine, { borderBottomWidth: 0 }]}>
                {data.delivery.timeOut}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: 12 }}>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.table}>
            <View
              style={[
                styles.tableRow,
                styles.heading4,
                { textAlign: 'center' },
              ]}
            >
              <View style={[styles.tableCol, { width: '15%' }]}>
                <View style={styles.tableCell}>
                  <Text>no. pieces</Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '55%' }]}>
                <View style={styles.tableCell}>
                  <Text>description of articles</Text>
                  <Text>special marks and exceptions</Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '15%' }]}>
                <View style={styles.tableCell}>
                  <Text>Weight</Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '15%' }]}>
                <View style={styles.tableCell}>
                  <Text>class or rate</Text>
                </View>
              </View>
            </View>

            {data.items.map((item, i) => (
              <View style={styles.tableRow} key={i}>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <View style={styles.tableCell}>
                    <Text style={{ textAlign: 'center' }}>
                      {item.noOfPieces}
                    </Text>
                  </View>
                </View>
                <View style={[styles.tableCol, { width: '55%' }]}>
                  <View style={styles.tableCell}>
                    <Text>{item.description}</Text>
                  </View>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <View style={styles.tableCell}>
                    <Text style={{ textAlign: 'center' }}>{item.weight}</Text>
                  </View>
                </View>
                <View style={[styles.tableCol, { width: '15%' }]}>
                  <View style={styles.tableCell}>
                    <Text style={{ textAlign: 'center' }}>{item.rate}</Text>
                  </View>
                </View>
              </View>
            ))}

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '15%' }]}>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: 'center' }}> </Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '55%' }]}>
                <View style={styles.tableCell}>
                  <Text style={{ fontWeight: 700, textAlign: 'right' }}>
                    Total Weight:{' '}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '15%' }]}>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: 'center', fontWeight: 700 }}>
                    {data.items.reduce((sum, item) => sum + +item.weight, 0)}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '15%' }]}>
                <View style={styles.tableCell}>
                  <Text style={{ textAlign: 'center' }}> </Text>
                </View>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '70%' }]}>
                <View style={styles.tableCell}>
                  <Text>
                    <Text style={styles.textBold}>Notes:</Text> {data.notes}
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '30%' }]}>
                <View style={styles.tableCell}>
                  <Text>Emergency Phone No. {data.emergencyPhone}</Text>
                </View>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={[styles.tableCol, { width: '70%' }]}>
                <View style={styles.tableCell}>
                  <Text style={styles.textSmall}>
                    SHIPPERS CERTIFICATION: This is to certify that the
                    above-named materials are properly classified, described,
                    packaged, marked and labeled, and are in proper condition
                    for transportation.
                  </Text>
                </View>
              </View>
              <View style={[styles.tableCol, { width: '30%' }]}>
                <View style={styles.tableCell}>
                  <Text>Signature: _____________</Text>
                  <Text>Title: ___________________</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.table, { width: '30%', borderLeftWidth: 0 }]}>
            <View style={[styles.tableRow, { margin: 0 }]}>
              <View style={[styles.tableCol, { width: '100%' }]}>
                <View style={styles.tableCell}>
                  <Text style={styles.textMedium}>
                    Subject to section 7 of conditions of bill of lading, if
                    this shipment is to be delivered to the consignee without
                    recourse on the consigner, the consigner shall sign the
                    following statement. The carrier shall not make delivery of
                    this shipment without payment of freight and all other
                    lawful charges.
                  </Text>
                  <Text style={styles.textBold}>Per:</Text>
                </View>
              </View>
            </View>
            <View style={[styles.tableRow, { margin: 0 }]}>
              <View style={[styles.tableCol, { width: '100%' }]}>
                <View style={styles.tableCell}>
                  <Text style={styles.textMedium}>
                    If charges are to be prepaid, write or stamp here, "to be
                    prepaid"
                  </Text>
                  <Text style={styles.textBold}>received: $</Text>
                </View>
              </View>
            </View>
            <View style={[styles.tableRow, { margin: 0 }]}>
              <View style={[styles.tableCol, { width: '100%' }]}>
                <View style={styles.tableCell}>
                  <Text style={styles.textMedium}>
                    To apply in prepayment of the charges on the property
                    described hereon.
                  </Text>
                  <Text> </Text>
                </View>
              </View>
            </View>
            <View style={[styles.tableRow, { margin: 0 }]}>
              <View style={[styles.tableCol, { width: '100%' }]}>
                <View style={styles.tableCell}>
                  <Text
                    style={[
                      styles.textBold,
                      { textAlign: 'center', textTransform: 'capitalize' },
                    ]}
                  >
                    Charges Advanced:
                  </Text>
                </View>
              </View>
            </View>

            <View style={[styles.tableRow, { margin: 0 }]}>
              <View style={[styles.tableCol, { width: '100%' }]}>
                <View style={styles.tableCell}>
                  <Text
                    style={[
                      styles.textBold,
                      { textAlign: 'center', textTransform: 'capitalize' },
                    ]}
                  >
                    Declared value of shippment
                  </Text>
                  <Text
                    style={[
                      styles.textBold,
                      { paddingTop: 8, paddingBottom: 8 },
                    ]}
                  >
                    $
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={[styles.textBold, { marginTop: 12 }]}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ marginRight: 20 }}>print name of receiver :</Text>
          <Text
            style={{
              textDecoration: 'underline',
              textTransform: 'capitalize',
              width: 180,
            }}
          >
            {data.reciever}
          </Text>
          <View style={{ flexDirection: 'column' }}>
            <Text>
              Date :{' '}
              <Text style={{ textDecoration: 'underline' }}>{data.date}</Text>
            </Text>
            <Text>
              Driver Name :{' '}
              <Text
                style={{
                  textDecoration: 'underline',
                  textTransform: 'capitalize',
                }}
              >
                {data.driver}
              </Text>
            </Text>
          </View>
        </View>
      </View>

      <View style={[styles.textBold, { marginTop: 12 }]}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ marginRight: 20 }}>received in good order</Text>
          <Text style={{ marginRight: 20 }}>
            x______________________________________
          </Text>
          <Text style={{ marginRight: 8 }}>driver signature:</Text>
          <Image style={styles.sig} source={data.signature} />
        </View>
      </View>
    </Page>
  </Document>
)

export default BillOfLadingPdf
