import {
  Button,
  IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { pdf } from '@react-pdf/renderer'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SignatureCanvas from 'react-signature-canvas'
import Footer from '../components/Footer'
import BillOfLadingPdf from '../components/Forms/BillOfLading/BillOfLadingPdf'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { encodeToFormData, getFormattedTime } from '../utils'

class LadingBillForm extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitState()
    this.sigPad = null
  }

  getInitState = () => ({
    status: 'init',
    formData: {
      shipper: '',
      shippingDate: '',
      shipmentNo: '',
      billNo: '',
      deliveryNo: '',
      truckNo: '',
      trailerNo: '',
      consignee: '',
      pickup: {
        timeIn: '',
        timeOut: '',
      },
      delivery: {
        timeIn: '',
        timeOut: '',
      },
      items: [],
      notes: '',
      emergencyPhone: '',
      reciever: '',
      driver: '',
      date: '',
    },
  })

  addItem = () => {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        items: prevState.formData.items.concat({
          noOfPieces: '',
          description: '',
          weight: '',
          rate: '',
        }),
      },
    }))
  }

  removeItem = i => {
    this.setState(prevState => {
      const { items } = prevState.formData
      items.splice(i, 1)
      return { formData: { ...prevState.formData, items } }
    })
  }

  handleChange = e => {
    const { name, value } = e.target
    if (name.includes('.')) {
      const [parent, child] = name.split('.')
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [parent]: { ...prevState.formData[parent], [child]: value },
        },
      }))
    } else {
      this.setState(prevState => ({
        formData: { ...prevState.formData, [name]: value },
      }))
    }
  }

  handleChangeInItem = (e, i) => {
    const { name, value } = e.target
    this.setState(prevState => {
      const { items } = prevState.formData
      items[i][name] = value
      return {
        formData: { ...prevState.formData, items },
      }
    })
  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ status: 'submitting' })

    const { formData: data } = this.state
    const formData = encodeToFormData({
      'form-name': e.target.getAttribute('name'),
      ...data,
    })

    // append pdf: generated by the react-pdf
    data.signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    const now = getFormattedTime()
    const blob = await pdf(<BillOfLadingPdf data={data} />).toBlob()
    formData.append('file', blob, `bill-of-lading-form_${now}.pdf`)

    fetch('/', {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (res.ok) this.setState({ status: 'success' })
        else this.setState({ status: 'failed' })
        //console.info(res)
      })
      .catch(err => {
        this.setState({ status: 'failed' })
        //console.error(err)
      })
  }

  render() {
    const { status, formData: values } = this.state
    return (
      <Layout>
        <SEO title="Bill of Lading Form | RoadLinx Shipping Company" />
        <section className="credit-application-form">
          <Container fluid className="credit-application-form__header">
            <h1 className="credit-application-form__title">
              Straight Bill of Lading
            </h1>
          </Container>
          <Container
            className="credit-application-form__container"
            fluid={false}
          >
            <form
              name="Bill of Lading Form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              hidden
            >
              <input type="text" name="shipper" />
              <input type="text" name="shippingDate" />
              <input type="text" name="shipmentNo" />
              <input type="text" name="billNo" />
              <input type="text" name="deliveryNo" />
              <input type="text" name="truckNo" />
              <input type="text" name="trailerNo" />
              <input type="text" name="consignee" />
              <input type="text" name="pickup.timeIn" />
              <input type="text" name="pickup.timeOut" />
              <input type="text" name="delivery.timeIn" />
              <input type="text" name="delivery.timeOut" />
              <input type="text" name="items[]" />
              <input type="text" name="notes" />
              <input type="text" name="emergencyPhone" />
              <input type="text" name="reciever" />
              <input type="text" name="driver" />
              <input type="text" name="date" />
              <input type="file" name="file" accept=".pdf" />
            </form>
            {(status === 'init' || status === 'submitting') && (
              <form
                name="Bill of Lading Form"
                method="POST"
                onSubmit={this.handleSubmit}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="Bill of Lading Form"
                />
                <input
                  type="hidden"
                  name="bot-field"
                  onChange={this.handleChange}
                />
                <h3 className="mt-4 mb-2">Shipper Information</h3>
                <Row>
                  <Col md={12}>
                    <TextField
                      fullWidth
                      placeholder="Shipper"
                      name="shipper"
                      value={values.shipper}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Shipping Date"
                      type="date"
                      name="shippingDate"
                      value={values.shippingDate}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Shipment #"
                      name="shipmentNo"
                      value={values.shipmentNo}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Bill of lading no."
                      name="billNo"
                      value={values.billNo}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="PO / Delivery No."
                      name="deliveryNo"
                      value={values.deliveryNo}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Truck No."
                      name="truckNo"
                      value={values.truckNo}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      placeholder="Trailer No."
                      name="trailerNo"
                      value={values.trailerNo}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <h3 className="mt-4 mb-2">Consignee Information</h3>
                <Row>
                  <Col md={12}>
                    <TextField
                      fullWidth
                      placeholder="Consignee"
                      name="consignee"
                      value={values.consignee}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={2}>
                    <span className="credit-application-form__font-16">Pick Up Time :</span>
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Time In"
                      type="time"
                      name="pickup.timeIn"
                      value={values.pickup.timeIn}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Time Out"
                      type="time"
                      name="pickup.timeOut"
                      value={values.pickup.timeOut}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={2}>
                    <span className="credit-application-form__font-16">Delivery Time : </span>
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Time In"
                      type="time"
                      name="delivery.timeIn"
                      value={values.delivery.timeIn}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Time Out"
                      type="time"
                      name="delivery.timeOut"
                      value={values.delivery.timeOut}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>

                <div className="mt-5 mb-2">
                  <span className="h3 mr-3">Item Details</span>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.addItem}
                    size="small"
                  >
                    Add New Item
                  </Button>
                </div>
                <TableContainer component={Paper}>
                  <Table aria-label="items table">
                    <colgroup>
                      <col style={{ width: '6%' }} />
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '55%' }} />
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '14%' }} />
                      <col style={{ width: '5%' }} />
                    </colgroup>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sr. #</TableCell>
                        <TableCell>No. Pieces</TableCell>
                        <TableCell>Description of Articles</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell>Class or Rate</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.items.length === 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={6}>
                            No item exists yet.
                          </TableCell>
                        </TableRow>
                      )}
                      {values.items.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {i + 1}
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              placeholder="No. of pieces"
                              type="number"
                              name="noOfPieces"
                              value={item.noOfPieces}
                              onChange={e => this.handleChangeInItem(e, i)}
                              size="medium"
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              placeholder="Description"
                              name="description"
                              value={item.description}
                              onChange={e => this.handleChangeInItem(e, i)}
                              size="medium"
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              placeholder="Weight"
                              name="weight"
                              value={item.weight}
                              onChange={e => this.handleChangeInItem(e, i)}
                              size="medium"
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              placeholder="Rate"
                              name="rate"
                              value={item.rate}
                              onChange={e => this.handleChangeInItem(e, i)}
                              size="medium"
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              onClick={() => this.removeItem(i)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Row className="mt-4">
                  <Col md={12}>
                    <TextField
                      fullWidth
                      placeholder="Notes"
                      name="notes"
                      value={values.notes}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Emergency Phone No."
                      name="emergencyPhone"
                      value={values.emergencyPhone}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Name of Reciever"
                      name="reciever"
                      value={values.reciever}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Name of Driver"
                      name="driver"
                      value={values.driver}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      fullWidth
                      placeholder="Date"
                      type="date"
                      name="fillingDate"
                      value={values.fillingDate}
                      onChange={this.handleChange}
                      size="medium"
                    />
                  </Col>
                </Row>
                <Row className="mt-5 align-items-center">
                  <Col lg={1}>
                    <div className="h5">Signature </div>
                    <div className="mt-5">
                      <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={() => this.sigPad.clear()}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <SignatureCanvas
                      ref={ref => {
                        this.sigPad = ref
                      }}
                      dotSize={1}
                      throttle={2}
                      canvasProps={{
                        width: 240,
                        height: 180,
                        className: 'sigCanvas',
                        style: { border: '2px solid black' },
                      }}
                    />
                  </Col>
                </Row>
                <Row className="m-0 mt-5">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={status === 'submitting'}
                  >
                    Submit
                  </Button>
                </Row>
              </form>
            )}
            {status === 'success' && (
              <div className="w-100 mt-5 text-center">
                <div className="mb-2 text-success">
                  <CheckCircleIcon style={{ fontSize: 24 }} />
                </div>
                <div className="mb-4">
                  Your form has been submitted successfully.
                </div>
              </div>
            )}
            {status === 'failed' && (
              <div className="w-100 mt-5 text-center">
                <div className="mb-2 text-danger">
                  <ErrorIcon style={{ fontSize: 24 }} />
                </div>
                <div className="mb-4 h5">Oops! something went wrong...</div>
                <div>
                  Please try reloading the page and submit the form again.
                </div>
              </div>
            )}
          </Container>
        </section>
        <Footer />
      </Layout>
    )
  }
}

export default LadingBillForm
